import React, { Fragment, Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "../../components/table";
import get from "../../helpers/lodashUtils/get";
import { getUploadTableData } from "../../actions/getOutletData";
import { AnchorText } from "../dashboard/components/ProjectCard";
import dateUtils from "../../helpers/utils/dateUtils";
import { StatusTagStyle } from "../../components/StatusTagStyle";
import {
    completed,
    accepted,
    processing,
    cancelled,
    cinnabar300,
    curiousBlue300,
    white,
} from "../../theme/colors";
import CheckBox from "../../components/ui/CheckBox";
import processTable from "../../actions/processOutlet";
import processSubProjectTable from "../../actions/processOutletSubProject";
// import { ConfirmationModal } from "./components/ConfirmationModal";
import triggerNotification from "../../actions/triggerNotification";
import { Button } from "../../components/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import appendToSearch from "../../helpers/utils/appendToSearch";
import styled from 'styled-components';
import { ConfirmationModal } from "../dashboard/components/ConfirmationModal";

const Bold = styled.p`
 font-weight: bold;
`;
class ReverseGeoTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            open: false,
            confirmationAction: null,
            confirmationText: `Confirming will create  these ${props.keyValue} in the  ${props.keyValue} master.
      This cannot be rolled back.`,
            isLoading: false,
            tickOrCancelClicked: false,
        };
    }

    componentDidMount = () => {
        this.setState({ isLoading: true });
        this.triggerTableData();
    };

    triggerTableData = () => {
        this.setState({ isLoading: true });
        const { location } = this.props;
        const qs = location.search ? location.search.replace("?", "&") : "";

        

        getUploadTableData(`${this.props.keyValue}${qs }`,location?.state?.bulkUploadType === "workflow_payout_plotting" ?`&config__name=sub_project&config__value=${location?.state?.subProjectId}` : "")
            .then((response) => {
                this.setState({
                    tableData: [...response.results],
                    count: response.count,
                    previous: response.previous,
                    next: response.next,
                    current: response.current_page_number,
                    pageSize: response.page_size,
                    isLoading: false,
                });
            })
            .catch((error) => {
                console.log("Error", error);
                this.setState({ isLoading: false });
            });
    };

    triggerCompleted = (id) => {
        this.setState({
            tickOrCancelClicked: true,
        });
        this.processTableAction(id, true);
    };

    triggerCancelled = (id) => {
        this.setState({
            tickOrCancelClicked: true,
        });
        this.processTableAction(id, false);
    };

    onPageChange = (pageNumber, b, c, d) => {
        const { history, location } = this.props;
        history.push({
            route: "/",
            state: { ...history.location.state },
            search: appendToSearch(location.search, {
                page: pageNumber,
            }),
        });
    };

    processTableAction = (id, isActivate) => {
        const { location } = this.props;
        let projId = get(location, "state.subProjectId");
        console.log('location---->',location.state);
        // let subProjectId = location.state?.metaDetailsss?.subProjectId;
        console.log('subProjectId---->',subProjectId);
        console.log("locationstate",location.state);

        const subProjectId = this.props.keyValue === "bulk_kyc"
        ? location.state?.metaDetailsss?.subProjectId
        : this.props.keyValue === "bulk_sku_assign"
        ? location.state.sub_project
        : null;
      

        this.setState({
            open: true,
            confirmationText: isActivate
                ? (<div>{`Confirming will create  these  ${this.props.keyValue} in the project  ${this.props.keyValue} master.
      This cannot be rolled back.`} <Bold>{`Any ${this.props.keyValue} upload in this project will be cancelled`} </Bold></div>)
                : `Confirming will cancel the job. This cannot be rolled back.`,
            confirmationAction: () => {
                console.log("this.props.keyValue===>>>",this.props.keyValue);
                if(this.props.keyValue==='bulk_kyc'|| this.props.keyValue==="bulk_sku_assign"){
                    processSubProjectTable(id, projId, isActivate, this.props.keyValue, this.props.isSKU, this.props.question_group,subProjectId)
                    .then((response) => {
                        this.props.notificationHandler({
                            messageText: response.detail,
                            status: "SUCCESS",
                        });
                        this.setState(
                            {
                                open: false,
                                confirmationAction: null,
                                tickOrCancelClicked: false,
                            },
                            () => {
                                this.triggerTableData();
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState(
                            {
                                open: false,
                                confirmationAction: null,
                                tickOrCancelClicked: false,
                            },
                            () => {
                                this.props.notificationHandler({
                                    messageText: error.detail,
                                    status: "FAILED",
                                });
                            }
                        );
                    });
                }else{
                    processTable(id, projId, isActivate, this.props.keyValue, this.props.isSKU, this.props.question_group)
                    .then((response) => {
                        this.props.notificationHandler({
                            messageText: response.detail,
                            status: "SUCCESS",
                        });
                        this.setState(
                            {
                                open: false,
                                confirmationAction: null,
                                tickOrCancelClicked: false,
                            },
                            () => {
                                this.triggerTableData();
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState(
                            {
                                open: false,
                                confirmationAction: null,
                                tickOrCancelClicked: false,
                            },
                            () => {
                                this.props.notificationHandler({
                                    messageText: error.detail,
                                    status: "FAILED",
                                });
                            }
                        );
                    });
                }
               
            },
        });
    };

    componentDidUpdate(prevProps) {
        const { location, refreshData, uploadedId } = this.props;
        if ((prevProps.refreshData === false && refreshData) || location.search !== prevProps.location.search || prevProps.uploadedId !== uploadedId) {
            this.setState({ isLoading: true });
            this.triggerTableData();
        }
    }

    render() {
        const {
            previous,
            next,
            pageSize,
            count,
            tableData,
            isLoading,
            tickOrCancelClicked,
        } = this.state;

        return (
            <Fragment>
                <ReactTable
                    rowHeight={"auto"}
                    title="History"
                    rightHeaderElements={
                        <Button
                            size="medium"
                            bgColor={curiousBlue300}
                            color={white}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.triggerTableData();
                            }}
                        >
                            <RefreshIcon /> Refresh
            </Button>
                    }
                    loading={isLoading}
                    current={previous ? previous + 1 : 1}
                    previousPage={previous}
                    nextPage={next}
                    pageSize={pageSize}
                    showTotalRecordText={true}
                    total={count}
                    onPageChange={this.onPageChange}
                    filters={[]}
                    columns={[
                        {
                            name: "Job ID",
                            key: "id",
                            sortable: false,
                            width: "80px",
                        },
                        {
                            name: "Uploaded  XLS",
                            key: "file",
                            sortable: false,
                            width: "190px",
                            wordBreak: "break-all",
                            render: (data) => {
                                const splitValues = data.split("/");
                                return (
                                    <AnchorText href={data} target={"_blank"}>
                                        {splitValues[splitValues.length - 1]}
                                    </AnchorText>
                                );
                            },
                        },
                        {
                            name: "Sub Project Id",
                            key: "config.sub_project",
                            sortable: false,
                            width: "120px",
                        },
                        {
                            name: "Updated By",
                            key: "created_by.name",
                            sortable: false,
                            width: "120px",
                        },
                        {
                            name: "Updated On",
                            key: "created",
                            sortable: false,
                            width: "160px",
                            render: (data) => {
                                const updatedDate = new Date(data);
                                return `${dateUtils().getFullDate(
                                    updatedDate
                                )} | ${dateUtils().getTimeValue(updatedDate)}`;
                            },
                        },
                        {
                            name: "Status",
                            key: "status",
                            sortable: false,
                            width: "150px",
                            render: (value) => {
                                let color;
                                switch (value) {
                                    case "completed":
                                        color = completed;
                                        break;
                                    case "processing":
                                        color = processing;
                                        break;
                                    case "active":
                                    case "accepted":
                                        color = accepted;
                                        break;
                                    case "cancelled":
                                        color = cancelled;
                                        break;
                                    case "failed":
                                        color = cancelled;
                                        break;
                                    default:
                                        color = completed;
                                        break;
                                }
                                return (
                                    <StatusTagStyle bordered borderColor={color} size="small">
                                        {value === "active" ? "accepted" : value}
                                    </StatusTagStyle>
                                );
                            },
                        },
                        {
                            name: `Total Data`,
                            key: "total_objects",
                            width: "70px",
                        },
                        {
                            name: "Total Clean",
                            key: "total_clean_objects",
                            width: "70px",
                            render: (data, obj) => {
                                return (
                                    <AnchorText
                                        href={obj.clean_objects_media_path}
                                        target={"_blank"}
                                    >
                                        {data || "--"}
                                    </AnchorText>
                                );
                            },
                        },
                        {
                            name: "Total Unclean",
                            key: "total_unclean_objects",
                            width: "70px",
                            render: (data, obj) => {
                                return (
                                    <AnchorText
                                        href={obj.unclean_objects_media_path}
                                        target={"_blank"}
                                    >
                                        {data || "--"}
                                    </AnchorText>
                                );
                            },
                        },
                        {
                            name: `Processed  Data`,
                            key: "total_validated_objects",
                            width: "80px",
                            render: (value, data) => `${value}/${data.total_objects}`,
                        },
                        {
                            name: "Waiting Queue",
                            key: "celery_queue_length",
                            width: "70px",
                            render: (value, data) => {
                                return (
                                    <AnchorText>
                                        {data.status === "processing"
                                            ? `${data.celery_queue_index}/${data.celery_queue_length}`
                                            : "--"}
                                    </AnchorText>
                                );
                            },
                        },
                        // {
                        //     name: " ",
                        //     key: "status",
                        //     sortable: false,
                        //     render: (data, { id, total_clean_objects }) => {
                        //         let tickAction = null;
                        //         let cancelAction = null;
                        //         if (data === "completed") {
                        //             tickAction = total_clean_objects
                        //                 ? this.triggerCompleted
                        //                 : null;
                        //             cancelAction = this.triggerCancelled;
                        //         } else if (
                        //             data === "cancelled" ||
                        //             data === "accepted" ||
                        //             data === "active" ||
                        //             data === "failed"        
                        //             //|| data === "under_activation"
                        //         ) {
                        //             return null;
                        //         } else {
                        //             tickAction = null;
                        //             cancelAction = this.triggerCancelled;
                        //         }
                        //         return (
                        //             <Fragment>
                        //                 <div
                        //                     onClick={() => {
                        //                         !tickOrCancelClicked && tickAction && tickAction(id);
                        //                     }}
                        //                 >
                        //                     <CheckBox
                        //                         checked={true}
                        //                         disabled={
                        //                             tickAction ? tickOrCancelClicked || false : true
                        //                         }
                        //                     />
                        //                 </div>
                        //                 <div
                        //                     onClick={() => {
                        //                         !tickOrCancelClicked &&
                        //                             cancelAction &&
                        //                             cancelAction(id);
                        //                     }}
                        //                 >
                        //                     <CheckBox
                        //                         checked={true}
                        //                         isTick={false}
                        //                         background={cinnabar300}
                        //                         disabled={
                        //                             cancelAction ? tickOrCancelClicked || false : true
                        //                         }
                        //                     />
                        //                 </div>
                        //             </Fragment>
                        //         );
                        //     },
                        // },
                    ]}
                    showPagination={true}
                    data={tableData || []}
                />
                <ConfirmationModal
                    open={this.state.open}
                    noAction={() => {
                        this.setState({
                            open: false,
                            confirmationAction: null,
                            tickOrCancelClicked: false,
                        });
                    }}
                    yesAction={this.state.confirmationAction}
                    confirmationText={this.state.confirmationText}
                    proceedText={"Do you want to proceed?"}
                    closeAction={() => {
                        this.setState({
                            open: false,
                            confirmationAction: null,
                            tickOrCancelClicked: false,
                        });
                    }}
                />
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        notificationHandler: bindActionCreators(triggerNotification, dispatch),
    };
};

export default withLastLocation(connect(null, mapDispatchToProps)(ReverseGeoTable));
