import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Tooltip } from '@material-ui/core';

const CustomDropdown = ({ options, value, onChange, label }) => {

  return (
    <FormControl fullWidth variant="outlined" style={{ width: '300px' }}> {/* Adjust the width here */}
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        style={{ width: '100%' }} // Ensures Select component takes full width of FormControl
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Optional: limit the height of the dropdown menu
              width: 150, // Adjust width of the dropdown menu
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            <Tooltip title={option} placement="right">
              <span>{option.length > 45 ? `${option.slice(0, 45)}...` : option}</span>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
