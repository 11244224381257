import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_OUTLETS } from '../../helpers/constants/apiUrl';

export default function getOutlets(req = '', workflowBased, isStatus) {
    if (req.includes('?show_only_special_action_states=false')) {
        req = req.replace('?show_only_special_action_states=false', '');
    } else if (req.includes('&show_only_special_action_states=false')) {
        req = req.replace('&show_only_special_action_states=false', '');
    }

    let reqString = workflowBased ? `?workflow_based=true${req.replace('?', '&')}` : req;

    // Check if `status` already exists in the request
    const hasStatus = /[?&]status=/.test(reqString);

    if (!hasStatus) {
        if (isStatus) {
            reqString += `&status=active`; // Only add if `status` is not already present
        }
    } else {
        // Ensure only one `status` parameter exists, keeping the first occurrence
        reqString = reqString.replace(/([?&]status=[^&]*)(&status=[^&]*)/, '$1');
    }

    // Avoid duplicate `include_temp=yes`
    if (!reqString.includes('include_temp=yes')) {
        reqString += `&include_temp=yes`;
    }

    return new Promise((res, rej) => {
        triggerSimpleAjax(
            `${GET_OUTLETS}${reqString}`,
            'GET',
            {},
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}
