import React, { useMemo, useState, useEffect } from "react";
import "./CollectedBills.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Slider from "react-slick";
import { Button } from "../../../components/Button";
import { ReactComponent as ExcelIcon } from "../../../components/icons/excel.svg";

import { useTable } from "react-table";
import { CustomNextArrow, CustomPrevArrow } from "./CustomArrow";
import getCollectedBills from "../../../actions/creation/getCollectedBills";
import ImageViewer from "./imageCollectedBills";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"; // Filled icon for selected button
import DeleteIcon from "@material-ui/icons/Delete"; // Import the Delete icon
import getAssignSku from "../../../actions/creation/getAssignSku";
import CustomDropdown from "./CollectedBillsDropdown";
import Add from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import getGstAndPanDetails from "../../../actions/creation/getGstAndPanDetails";
import { bindActionCreators } from "redux";
import triggerNotification from "../../../actions/triggerNotification";
import { connect } from "react-redux";
import ImageIcon from "@material-ui/icons/Image";
import { DASHBOARDROUTES } from "../../../helpers/constants/routes";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"; // Import Material-UI components from core
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import { DELETE_COLLECTED_BILLS_SKU } from "../../../helpers/constants/apiUrl";
import createSkuCollectedBills from "../../../actions/creation/createSKUBills";
import getAddNewSkuData from "../../../actions/creation/getNewSkuData";
import createaddskuexcel from "../../../actions/creation/createAddNewSkuExcel";
import { black300, black400, black700, white } from "../../../theme/colors";
import { AppConfig } from "../../../app-config";
import { green } from "@material-ui/core/colors";

let getcategoryDetailsStaticQueryParams = "&no-pagination&exclude_retailers=true";


const CollectedBills = (props) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardDataForSKus, setCardDataForSKus] = useState();
  const [tableData, setTableData] = useState([]);
  const [skuName, setSkuName] = useState([]);
  const [savedCard, setSavedCard] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [results, setResult] = useState([]);
  const [refreshData, setRefreshData] = useState(false); // State to trigger data refresh
  const [newAddedSKu, setNewAddedSKu] = useState([]); // State to trigger data refresh
  const [selectedSkuID, setSelectedSkuID] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [hasNewSku, setHasNewSku] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [localInvoiceHeaders, setLocalInvoiceHeaders] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [errorIndexes, setErrorIndexes] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const req = props.outletDetails.workflow_object;
        setLoading(true);
        const response = await getCollectedBills(req);        
        setCardDataForSKus(response);
        setLoading(false);
      } catch (error) {
        setError(error.message || "Something went wrong");
        setLoading(false);
      }
    }
    fetchData();
  }, [refreshData]);

  //Required this for the New SKu Data

  useEffect(() => {
    if (selectedCard === "new-sku") {
      const req = props.outletDetails.workflow_object;
      async function fetchNewSKuData() {
        try {
          // const req = props.outletDetails.workflow_object;
          setLoading(true);
          const response = await getAddNewSkuData(req);
          setNewAddedSKu(response);
          setLoading(false);
        } catch (error) {
          setError(error.message || "Something went wrong");
          setLoading(false);
        }
      }
      fetchNewSKuData();
    }
  }, [selectedCard]);

  useEffect(() => {
    const subProjectSlugId = props.outletDetails.sub_project.slug;
    const req = `?sub_project__slug=${subProjectSlugId}${getcategoryDetailsStaticQueryParams}`;

    getAssignSku(req).then((response) => {
      const { results } = response;
      const skuNames = results.map((name) => name.sku_name);
      const skuData = results.map((item) => ({
        sku_name: item.sku_name,
        sku: item.sku,
      }));

      setResult(skuData);
      setSkuName(skuNames);
    });
  }, [props.outletDetails.sub_project.slug]);

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenDialog = (skuID, rowIndex) => {
    setSelectedSkuID(skuID);
    setSelectedRowIndex(rowIndex);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    handleDeleteRow(); // Call your existing delete function
    setOpenDialog(false); // Close the dialog
  };

  const checkForNewSkus = () => {
    const newSkuExists = tableData.some(
      (row) =>
        (row.sku_name &&
          skuName.length > 0 &&
          !skuName.includes(row.sku_name)) ||
        (row.add_on_sku &&
          skuName.length > 0 &&
          !skuName.includes(row.add_on_sku)) // Check for both sku_name and add_on_sku, and ensure skuName is not empty
    );

    setHasNewSku(newSkuExists);
  };

  // Call this function whenever tableData changes
  useEffect(() => {
console.log(`table data new >>>> ${JSON.stringify(tableData)}`);

    checkForNewSkus();
  }, [tableData]);

  const [validationErrors, setValidationErrors] = useState([]);

  const validateTableData = () => {
    const errors = [];
    const sku = cardDataForSKus?.results[selectedCard];

    const currentskuData = sku.simplifiedinvoiceskudata?.map((skulist) => {
      return skulist.simplifiedinvoiceskudetails;
    });

    tableData.forEach((row, rowIndex) => {
      Object.entries(row).forEach(([columnId, value]) => {
        let extractedData = null;

        // Find the corresponding validation details for the columnId
        currentskuData.forEach((innerArray) => {
          innerArray.forEach((item) => {
            if (item.sku_template_field.toString() === columnId) {
              extractedData = {
                sku_template_field: item.sku_template_field,
                sku_template_field_name: item.sku_template_field_name,
                sku_template_field_type: item.sku_template_field_type,
                validations: item.validations,
              };
            }
          });
        });
        if (extractedData) {
          const { validations, sku_template_field_type } = extractedData;

          // Trim the input value
          const trimmedValue =
            value.value !== null && value.value !== undefined
              ? String(value.value).trim()
              : "";

          // Check if field is empty and mandatory
          if (trimmedValue === "") {
            errors.push({
              rowIndex,
              columnId,
              message: `${extractedData.sku_template_field_name} cannot be empty.`,
            });
            return; // Skip further validation if empty
          }

          switch (sku_template_field_type) {
            case "text":
              // Validate text field length
              if (
                validations.min_length &&
                trimmedValue.length < validations.min_length
              ) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be at least ${validations.min_length} characters.`,
                });
              }
              if (
                validations.max_length &&
                trimmedValue.length > validations.max_length
              ) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must not exceed ${validations.max_length} characters.`,
                });
              }
              break;

            case "number":
              const numericValue = parseFloat(trimmedValue);
              if (isNaN(numericValue)) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be a valid number.`,
                });
              } else {
                // Validate number limits
                if (
                  validations.lower_limit &&
                  numericValue < parseFloat(validations.lower_limit)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be greater than or equal to ${validations.lower_limit}.`,
                  });
                }
                if (
                  validations.upper_limit &&
                  numericValue > parseFloat(validations.upper_limit)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be less than or equal to ${validations.upper_limit}.`,
                  });
                }
                if (!validations.allow_decimals && trimmedValue.includes(".")) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be an integer.`,
                  });
                }
              }
              break;

            case "dropdown":
              if (
                validations.choices &&
                !validations.choices.split(";").includes(trimmedValue)
              ) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be one of the allowed choices: ${validations.choices}.`,
                });
              }
              break;

            case "date":
              const dateValue = Date.parse(trimmedValue);
              if (isNaN(dateValue)) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be a valid date.`,
                });
              } else {
                const parsedDate = new Date(dateValue);
                if (
                  validations.lower_than &&
                  parsedDate >= new Date(validations.lower_than)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be earlier than ${validations.lower_than}.`,
                  });
                }
                if (
                  validations.greater_than &&
                  parsedDate <= new Date(validations.greater_than)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be later than ${validations.greater_than}.`,
                  });
                }
              }
              break;

            case "time":
              const timeValue = Date.parse(`1970-01-01T${trimmedValue}Z`);
              if (isNaN(timeValue)) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be a valid time.`,
                });
              } else {
                const parsedTime = new Date(timeValue);
                if (
                  validations.lower_than &&
                  parsedTime >=
                    new Date(`1970-01-01T${validations.lower_than}Z`)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be earlier than ${validations.lower_than}.`,
                  });
                }
                if (
                  validations.greater_than &&
                  parsedTime <=
                    new Date(`1970-01-01T${validations.greater_than}Z`)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be later than ${validations.greater_than}.`,
                  });
                }
              }
              break;

            default:
              console.warn(
                `Unhandled type "${sku_template_field_type}" for field: ${extractedData.sku_template_field_name}`
              );
              break;
          }
        } else {
          console.warn(
            `No matching field found for columnId: ${columnId} in currentskuData`
          );
        }
      });
    });

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSaveNext = async () => {
    const isValid = validateTableData();

    if (!isValid) {
      return;
    }
    setSavedCard(selectedCard);
    setEditLoader(true);

    const currentCardData = cardDataForSKus?.results[selectedCard];

    // Perform validation: Check if any required field is empty
    const hasEmptyFields = tableData.some((row, rowIndex) => {
      const emptyFields = Object.entries(row).filter(([key, value]) => {
        return value === "" || value === null || value === undefined;
      });

      return false;
    });

    if (hasEmptyFields) {
      // Stop further processing if validation fails
      setEditLoader(false); // Hide loader
      return;
    }

    setEditLoader(true);
    const tableDataMap = tableData.reduce((map, row) => {
      map[row.id] = row; // Assuming `id` is unique, else use `sku_name`
      return map;
    }, {});

    let isOuterEdit = false;
    let isOuterSkuDataEdit = false;

    const getFieldName = (key) => {
      const fieldDetail = currentCardData.assigned_sku_template_field.find(
        (field) => field.id === parseInt(key)
      );

      return fieldDetail ? fieldDetail.name : key; // Fallback to key if name is not found
    };

    const getFieldType = (key) => {
      const fieldDetail = currentCardData.assigned_sku_template_field.find(
        (field) => field.id === parseInt(key)
      );
      return fieldDetail ? fieldDetail.type : key; // Fallback to key if type is not found
    };

    const updatedSkuData = currentCardData.simplifiedinvoiceskudata?.map(
      (skuItem) => {
        const matchingRow = tableDataMap[skuItem.id]; // Match by `id` instead of `sku_name`

        let isSkuDataEdited = false;

        const updatedSkuDetails = skuItem.simplifiedinvoiceskudetails.map(
          (skuDetail, index) => {
            const tableValue = matchingRow
              ? matchingRow[skuDetail.sku_template_field]
              : undefined;

            // Preserve the current single_input if tableValue is undefined

            if (tableValue === undefined) {
              return {
                ...skuDetail,
                is_edit: isSkuDataEdited,
                single_input: skuDetail.single_input,
              };
            }

            // If the tableValue is an empty string, preserve the existing single_input
            if (tableValue === "") {
              return {
                ...skuDetail,
                is_edit: true,
                single_input: "",
              };
            }

            // If the tableValue is different from the current single_input, update it
            if (tableValue !== skuDetail.single_input) {
              isSkuDataEdited = true;
              isOuterEdit = true;
              return {
                ...skuDetail,
                is_edit: true,
                single_input: tableValue,
              };
            }

            return skuDetail;
          }
        );

        // Handle additional fields from currentCardData.assigned_sku_template_field
        currentCardData.assigned_sku_template_field.forEach((field) => {
          const fieldExists = skuItem.simplifiedinvoiceskudetails.some(
            (detail) => detail.sku_template_field === field.id
          );

          if (!fieldExists) {
            const tableValue = matchingRow ? matchingRow[field.id] : "";

            updatedSkuDetails.push({
              id: null,
              sku_template_field: field.id,
              sku_template_field_type: field.type,
              sku_template_field_name: field.name,
              single_input: tableValue || "",
              simplifiedinvoiceskuimageanswer: [],
              is_edit: true,
            });

            isSkuDataEdited = true;
            isOuterEdit = true;
          }
        });

        if (isSkuDataEdited) {
          isOuterSkuDataEdit = true;
        }

        const updatedSku =
          matchingRow && matchingRow.sku !== undefined
            ? matchingRow.sku
            : skuItem.sku; // Use the SKU from matchingRow or fallback

        const isSkuNameEdited =
          matchingRow && matchingRow.sku_name !== skuItem.sku_name;

        return {
          ...skuItem,
          simplifiedinvoiceskudetails: updatedSkuDetails, // Keep the updated details intact
          sku_name: matchingRow ? matchingRow.sku_name : skuItem.sku_name,
          sku: updatedSku, // Include `sku`
          is_edit: isSkuNameEdited || isSkuDataEdited,
          is_edit_simplifiedinvoiceskudetails: isSkuDataEdited,
        };
      }
    );

    // Check if updatedSkuData has changed from the original data
    const hasChanges = !currentCardData.simplifiedinvoiceskudata.every(
      (originalSkuItem, index) => {
        const updatedSkuItem = updatedSkuData[index];
        return (
          originalSkuItem.sku === updatedSkuItem.sku &&
          originalSkuItem.sku_name === updatedSkuItem.sku_name &&
          JSON.stringify(originalSkuItem.simplifiedinvoiceskudetails) ===
            JSON.stringify(updatedSkuItem.simplifiedinvoiceskudetails)
        );
      }
    );

    // If changes are detected, set both edit flags to true
    if (hasChanges) {
      updatedSkuData.forEach((skuItem) => {
        skuItem.is_edit = true;
        skuItem.is_edit_simplifiedinvoiceskudetails = true;
      });
      isOuterSkuDataEdit = true; // Set global edit flag
    }

    // Now, updatedSkuData is ready to be used

    const newAddOnSkuRows = currentCardData.simplifiedinvoiceaddonsku?.map(
      (addOnSkuItem) => {
        const matchingRow = tableDataMap[addOnSkuItem.id]; // Match by `id` instead of `sku_name`

        const updatedDetails = addOnSkuItem.simplifiedinvoiceskudetails.map(
          (skuDetail) => {
            const tableValue = matchingRow
              ? matchingRow[skuDetail.sku_template_field]
              : undefined;

            // Preserve the current single_input if tableValue is undefined
            if (tableValue === undefined) {
              return {
                ...skuDetail,
                single_input: skuDetail.single_input,
              };
            }

            // If the tableValue is an empty string, preserve the existing single_input
            if (tableValue === "") {
              return {
                ...skuDetail,
                single_input: "", // Reset single_input if value is empty
              };
            }

            // If the tableValue is different from the current single_input, update it
            if (tableValue !== skuDetail.single_input) {
              return {
                ...skuDetail,
                single_input: tableValue, // Update with the new table value
              };
            }

            return skuDetail; // Return unchanged skuDetail if no updates are necessary
          }
        );

        currentCardData.assigned_sku_template_field.forEach((field) => {
          const fieldExists = updatedDetails.some(
            (detail) => detail.sku_template_field === field.id
          );

          if (!fieldExists) {
            const tableValue =
              matchingRow && matchingRow[field.id] !== undefined
                ? matchingRow[field.id]
                : ""; // Use empty string as fallback if no value is found

            updatedDetails.push({
              sku_template_field: field.id,
              sku_template_field_type: field.type,
              sku_template_field_name: field.name,
              single_input: tableValue || "", // Fallback to empty string if value not found
              simplifiedinvoiceskuimageanswer: [],
            });
          }
        });

        return {
          id: addOnSkuItem.id,
          sku_name: addOnSkuItem.add_on_sku || "", // Use add_on_sku as sku_name
          sku: matchingRow ? matchingRow.sku : "", // Use SKU from matchingRow or fallback
          simplifiedinvoiceskudetails: updatedDetails, // Use updated details
        };
      }
    );

    const newRows = tableData
      .filter((row) => row.isNew)
      .map((row) => {
        const matchedSku = results.find(
          (result) => result.sku_name === row.sku_name
        );
        return {
          sku_name: row.sku_name || "",
          sku: matchedSku ? matchedSku.sku : "",
          simplifiedinvoiceskudetails: Object.entries(row)
            .filter(([key]) => key !== "id" && key !== "isNew")
            .map(([key, value]) => ({
              sku_template_field: key,
              sku_template_field_name: getFieldName(key),
              sku_template_field_type: getFieldType(key),
              single_input: value,
              simplifiedinvoiceskuimageanswer: [],
            })),
        };
      });

    const payload = {
      ...currentCardData,
      simplifiedinvoiceskudata: updatedSkuData,
      new_simplifiedinvoiceskudata: [...newRows, ...newAddOnSkuRows],
      is_edit: true,
      is_edit_simplifiedinvoiceskudata: true,
    };

    try {
      const response = await createSkuCollectedBills(
        currentCardData.id,
        payload
      );
      setRefreshData((prev) => !prev);
    } catch (error) {
    } finally {
      setEditLoader(false);
    }

    if (selectedCard < cardDataForSKus?.results.length - 1) {
      setSelectedCard(selectedCard + 1);
    } else {
      props.setIsSliderComplete(true);
    }
  };

  const columns = useMemo(() => {
    const firstSkuData =
      cardDataForSKus?.results[selectedCard]?.assigned_sku_template_field || [];

    const seenHeaders = new Set();
    return [
      {
        Header: "SKU Name", // Always have "SKU Name" as the first column
        accessor: "sku_name",
      },
      // Directly map over firstSkuData if it's an array
      ...(firstSkuData?.map((detail) => {
        if (!detail) return null; // Handle undefined detail
        // Using `name` (or other relevant field) as the unique header
        let uniqueHeader = detail.name || `Detail ${detail.id}`;
        let accessor = detail.id?.toString() || "undefined_id";

        // Handle duplicate accessor keys
        let index = 1;
        while (seenHeaders.has(accessor)) {
          accessor = `${uniqueHeader}_${index}`;
          index++;
        }
        seenHeaders.add(accessor);

        return {
          Header: uniqueHeader, // Display field name as header
          accessor: accessor, // Key for accessing this field's value
        };
      }) || []),
    ];
  }, [selectedCard, cardDataForSKus]);

  const data = useMemo(() => {
    const currentResults = cardDataForSKus?.results[selectedCard] || {};

    // Function to map data for both sku types, with conditional key for add_on_sku and including the id
    const mapSkuData = (skuDataArray, invoiceDate, isAddon = false) =>
      skuDataArray?.map((skuData) => ({
        id: skuData.id, // Include the id field
        sku: skuData.sku,
        [isAddon ? "add_on_sku" : "sku_name"]: isAddon
          ? skuData.add_on_sku
          : skuData.sku_name,
        invoice_date: invoiceDate,
        ...skuData.simplifiedinvoiceskudetails.reduce(
          (acc, detail) => ({
            ...acc,
            [detail.sku_template_field.toString()]: {
              value: detail.single_input,
              type: detail.sku_template_field_type,
              validations: detail.validations,
            },
          }),
          {}
        ),
      })) || [];

    // Map both simplifiedinvoiceskudata and simplifiedinvoiceaddonsku
    const currentSkuData = mapSkuData(
      currentResults.simplifiedinvoiceskudata,
      currentResults.invoice_date
    );

    const currentAddonSkuData = mapSkuData(
      currentResults.simplifiedinvoiceaddonsku,
      currentResults.invoice_date,
      true
    );

    // Combine both data sets
    const combinedData = [...currentSkuData, ...currentAddonSkuData];

    const emptyRow = {};
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });

    console.log(`combinedData >> >> >> ${JSON.stringify(combinedData)}`);
    
    return combinedData;
  }, [selectedCard, columns, cardDataForSKus]);

  const addEmptyRow = () => {
    const emptyRow = {
      id: Date.now(), // Unique identifier using timestamp or you can use a UUID library
      isNew: true, // Flag to indicate it's a new row
    };
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });
    

    console.log("Add data in table 1");
    
    setTableData((prevData) => [...prevData, emptyRow]);
  };

  useEffect(() => {
    // Initialize the tableData when data changes
    console.log("Add data in table 2");
    setTableData(data);
  }, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

  const handleInputChange = (event, rowIndex, columnId, value, type) => {
    const newValue = event.target.value;

    console.log("Add data in table 3");
    setTableData((prevData) => {
      const updatedData = [...prevData];

      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [columnId]: {
          ...updatedData[rowIndex][columnId],
          value: newValue,
        },
      };      

      // updatedData[rowIndex] = {
      //   ...updatedData[rowIndex],
      //   [columnId]: newValue,
      // };

      return updatedData;
    });
  };

  const newAddedSKuTest = newAddedSKu?.results ?? [];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  

  const getInvoiceHeaders = (data, index) => {
    const simplifiedInvoiceHeaderAnswer = data?.results?.[index]?.simplifiedinvoiceheaderanswer;
  
    if (!Array.isArray(simplifiedInvoiceHeaderAnswer)) {
      return [];
    }
  
    return simplifiedInvoiceHeaderAnswer.map((item) => ({
      header: item?.invoice_header_name ,
      singleInput: item?.single_input ,
      invoiceHeaderValidation: item.validations,
      invoiceHeadersTypes: item.invoice_header_type,
      type: item?.invoice_header_type ,
      simplifiedinvoiceheaderanswerdetails: Array.isArray(item?.simplifiedinvoiceheaderanswerdetails)
        ? item.simplifiedinvoiceheaderanswerdetails.map((detail) => ({
            gst_legal_name: detail?.gst_legal_name ,
            gst_registration_status: detail?.gst_registration_status ,
            gst_trade_name: detail?.gst_trade_name ,
            pan_holder_name: detail?.pan_holder_name ,
          }))
        : [],
    }));
  };


  const invoiceHeaders = useMemo(
    () => getInvoiceHeaders(cardDataForSKus, selectedCard),
    [cardDataForSKus, selectedCard]
  );

  useEffect(() => {
    setLocalInvoiceHeaders([...invoiceHeaders]);
  }, [invoiceHeaders]);



  const handleSingleInputChange = (e, index) => {
    const value = e.target.value;

    let formattedValue = value;
    if (e.target.type === "date") {
      const inputDate = new Date(value);
      formattedValue = inputDate.toISOString().split("T")[0];
    }

    if (e.target.type === "time") {
      const isAM = value.includes("AM") || value.includes("PM");
      if (isAM) {
        const [time, period] = value.split(" ");
        const [hours, minutes] = time.split(":").map(Number);
        let formattedHours = hours;

        if (period === "AM" && formattedHours === 12) {
          formattedHours = 0;
        } else if (period === "PM" && formattedHours !== 12) {
          formattedHours += 12;
        }

        formattedValue = `${String(formattedHours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}:00`; // Add seconds as 00
      } else {
        const [hours, minutes] = value.split(":").map(Number);
        formattedValue = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}:00`; // Add seconds as 00
      }
    }

    const updatedHeaders = [...localInvoiceHeaders];
    updatedHeaders[index].singleInput = formattedValue;
    if (updatedHeaders[index].type === "gst_number") {
      updatedHeaders[index].isVerified = false; // Set to "Validate"
      
      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = updatedHeaders[
        index
      ].simplifiedinvoiceheaderanswerdetails.map((item) => ({
        ...item,
        gst_legal_name: "",
        gst_trade_name: "",
        gst_registration_status: "",
      }));
    } else if (updatedHeaders[index].type === "pan_number") {
      updatedHeaders[index].isVerified = false; // Set to "Validate"
  
      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = updatedHeaders[
        index
      ].simplifiedinvoiceheaderanswerdetails.map((item) => ({
        ...item,
        pan_holder_name: "",
      
      }));
    }
    setLocalInvoiceHeaders(updatedHeaders);

    if (formattedValue.trim() !== "") {
      setErrorIndexes((prev) => prev.filter((i) => i !== index));
    }
  };


  const handleActionButtonClick = (index) => {
    const { notificationHandler } = props;
  
    const updatedHeaders = [...localInvoiceHeaders];
  
    // Ensure simplifiedinvoiceheaderanswerdetails is initialized if empty
    updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = 
      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails || [
        {
          gst_legal_name: '',
          gst_trade_name: '',
          gst_registration_status: ''
        }
      ];
  
    const req = `?input=${updatedHeaders[index].singleInput}&input_type=GST`;
  
    getGstAndPanDetails(req)
      .then((response) => {
        console.log('response898', response);
  
        // Map through simplifiedinvoiceheaderanswerdetails and update values
        updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map((item) => ({
          ...item,
          gst_legal_name: response.lgnm || item.gst_legal_name,
          gst_registration_status: response.dty || item.gst_registration_status,
          gst_trade_name: response.tradeNam || item.gst_trade_name,
        }));
  
        // Check if all required fields are filled for verification
        const isVerified = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.every(
          (item) =>
            item.gst_legal_name &&
            item.gst_trade_name &&
            item.gst_registration_status
        );
  
        // Update the verification status
        updatedHeaders[index].isVerified = isVerified;
  
        console.log("updateheaders898", updatedHeaders);
  
        setLocalInvoiceHeaders(updatedHeaders);
        notificationHandler({
          messageText: `GST number validated Successfully.`,
          status: "SUCCESS",
        });
      })
      .catch((error) => {
        console.error(error.input);
        notificationHandler({
          messageText: `Invalid GST format. The given GST number is not in proper format.`,
          status: "FAILED",
        });
      });
  };
  
  const handlePanButtonClick = (index) => {
    const {notificationHandler}=props;
    const updatedHeaders = [...localInvoiceHeaders]; // Create a copy of the headers
    console.log('updatedHeaders',updatedHeaders);
    const req = `?input=${updatedHeaders[index].singleInput}&input_type=PAN`;
  
    getGstAndPanDetails(req).then((response) => {
        updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map((item) => {
  
            return {
                ...item,
                pan_holder_name: response.name || item.pan_holder_name,  
               
            };
        });
  
        const isVerified = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.every(
          (item) =>
            item.pan_holder_name 
        );
        updatedHeaders[index].isVerified = isVerified;
        setLocalInvoiceHeaders(updatedHeaders);
  
        notificationHandler({
          messageText: `PAN number. Validated Successfully.`,
          status: "SUCCESS",
        });
    }).catch((error) => {
        console.error("Error fetching GST and PAN details", error);
        notificationHandler({
          messageText: `Invalid PAN number. The given PAN number is not in proper format or invalid.`,
          status: "FAILED",
        });
    });
  };



  const handleSaveInvoiceHeader = async () => {
    const { notificationHandler}=props;

    const errors = [];
    const errorMsgs = {};

    localInvoiceHeaders.forEach((detail, index) => {
      const value = detail.singleInput?.trim();
      const invoiceHeaderType = detail.invoiceHeadersTypes;
      const headerValidation = detail.invoiceHeaderValidation || {};
      const {
        min_length,
        max_length,
        greater_than,
        lower_than,
        min_images_count,
        max_images_count,
        choices,
      } = headerValidation;

      if (value === "") {
        errors.push(index);
        errorMsgs[index] = `${detail.header} cannot be empty.`;
        return;
      }

      switch (invoiceHeaderType) {
        case "text":
          if (
            (min_length && value.length < parseInt(min_length)) ||
            (max_length && value.length > parseInt(max_length))
          ) {
            errors.push(index);
            errorMsgs[
              index
            ] = `${detail.header} must be between ${min_length} and ${max_length} characters.`;
          }
          break;

        case "date":
          const inputDate = new Date(value);
          const inputDateStr = inputDate.toISOString().split("T")[0];

          const lowerLimitDate = lower_than ? new Date(lower_than) : null;
          const upperLimitDate = greater_than ? new Date(greater_than) : null;

          let adjustedLowerLimitDateStr = lowerLimitDate
            ? lowerLimitDate.toISOString().split("T")[0]
            : null;
          let adjustedUpperLimitDateStr = upperLimitDate
            ? upperLimitDate.toISOString().split("T")[0]
            : null;

          if (adjustedLowerLimitDateStr && adjustedUpperLimitDateStr) {
            if (
              new Date(adjustedLowerLimitDateStr) >
              new Date(adjustedUpperLimitDateStr)
            ) {
              const temp = adjustedLowerLimitDateStr;
              adjustedLowerLimitDateStr = adjustedUpperLimitDateStr;
              adjustedUpperLimitDateStr = temp;
            }
          }

          if (
            (adjustedLowerLimitDateStr &&
              inputDateStr < adjustedLowerLimitDateStr) ||
            (adjustedUpperLimitDateStr &&
              inputDateStr > adjustedUpperLimitDateStr)
          ) {
            errors.push(index);
            errorMsgs[
              index
            ] = `${detail.header} must be between ${greater_than} and ${lower_than}.`;
          }
          break;

        case "time":
          if (value === "") {
            errors.push(index);
            errorMsgs[index] = `${detail.header} cannot be empty.`;
            break;
          }

          if (value) {
            const formattedInputTime =
              value.length === 5 ? `${value}:00` : value;

            const [inputHours, inputMinutes, inputSeconds] = formattedInputTime
              .split(":")
              .map(Number);
            const inputTime = new Date();
            inputTime.setHours(inputHours, inputMinutes, inputSeconds, 0);

            const lowerLimitTime = lower_than
              ? (() => {
                  const [hours, minutes] = lower_than.split(":").map(Number);
                  const time = new Date();
                  time.setHours(hours, minutes, 0, 0);
                  return time;
                })()
              : null;

            const upperLimitTime = greater_than
              ? (() => {
                  const [hours, minutes] = greater_than.split(":").map(Number);
                  const time = new Date();
                  time.setHours(hours, minutes, 0, 0);
                  return time;
                })()
              : null;

            if (
              (lowerLimitTime && inputTime < lowerLimitTime) ||
              (upperLimitTime && inputTime > upperLimitTime)
            ) {
              errors.push(index);
              errorMsgs[
                index
              ] = `${detail.header} must be between ${lower_than} and ${greater_than}.`;
            }
          }
          break;

        case "multiple_image":
          const imageCount = value ? value.split(",").length : 0;
          if (
            (min_images_count && imageCount < parseInt(min_images_count)) ||
            (max_images_count && imageCount > parseInt(max_images_count))
          ) {
            errors.push(index);
            errorMsgs[
              index
            ] = `${detail.header} must have between ${min_images_count} and ${max_images_count} images.`;
          }
          break;

        case "dropdown":
          if (!choices.includes(value)) {
            errors.push(index);
            errorMsgs[
              index
            ] = `Invalid selection for ${detail.header}. Please select a valid option.`;
          }
          break;

        default:
          console.warn(`Unknown field type: ${invoiceHeaderType}`);
          break;
      }
    });

    if (errors.length > 0) {
      setErrorIndexes(errors);
      setErrorMessages(errorMsgs);
      return;
    }

    setErrorIndexes([]);
    setErrorMessages({});
    const currentCardData = cardDataForSKus?.results[selectedCard];
    const updatedSimplifiedInvoiceHeaders = [
      ...currentCardData.simplifiedinvoiceheaderanswer,
    ];
    let hasChanges = false;

    localInvoiceHeaders.forEach((localHeader) => {
      updatedSimplifiedInvoiceHeaders.forEach((header) => {
        if (
          header.invoice_header_type === 'gst_number' &&
          header.simplifiedinvoiceheaderanswerdetails.length > 0 &&
          localHeader.simplifiedinvoiceheaderanswerdetails.length > 0
        ) {
          const localDetails = localHeader.simplifiedinvoiceheaderanswerdetails[0];
          const simplifiedDetails = header.simplifiedinvoiceheaderanswerdetails[0]; // Assuming single detail in both cases
        
    
          const { gst_legal_name, gst_registration_status, gst_trade_name } = localDetails;
    
          // Update values explicitly allowing empty strings and non-empty updates
          simplifiedDetails.gst_legal_name =
            gst_legal_name !== undefined && gst_legal_name !== null
              ? gst_legal_name
              : simplifiedDetails.gst_legal_name;
    
          simplifiedDetails.gst_registration_status =
            gst_registration_status !== undefined && gst_registration_status !== null
              ? gst_registration_status
              : simplifiedDetails.gst_registration_status;
    
          simplifiedDetails.gst_trade_name =
            gst_trade_name !== undefined && gst_trade_name !== null
              ? gst_trade_name
              : simplifiedDetails.gst_trade_name;
    
          header.is_edit = true; // Mark the header as edited
          hasChanges = true; // Flag that there are changes
        }
    
        // Update pan_number details
        if (
          header.invoice_header_type === 'pan_number' &&
          header.simplifiedinvoiceheaderanswerdetails.length > 0 &&
          localHeader.simplifiedinvoiceheaderanswerdetails.length > 0
        ) {
          const localDetails = localHeader.simplifiedinvoiceheaderanswerdetails[0];
          const simplifiedDetails = header.simplifiedinvoiceheaderanswerdetails[0]; // Assuming single detail in both cases
    
          const { pan_holder_name } = localDetails;
    
          // Update values explicitly allowing empty strings and non-empty updates
          simplifiedDetails.pan_holder_name =
            pan_holder_name !== undefined && pan_holder_name !== null
              ? pan_holder_name
              : simplifiedDetails.pan_holder_name;
    
          header.is_edit = true; // Mark the header as edited
          hasChanges = true; // Flag that there are changes
        }
    
        // Check and update single_input value if needed
        if (header.invoice_header_name === localHeader.header) {
          if (header.single_input.trim() !== localHeader.singleInput.trim()) {
            header.single_input = localHeader.singleInput;
            header.is_edit = true; // Mark the header as edited
            hasChanges = true; // Flag that there are changes
          }
        }
      });
    });
    const payload = {
      ...currentCardData,
      is_edit: hasChanges,
      is_edit_simplifiedinvoiceheaderanswer: hasChanges,
      simplifiedinvoiceheaderanswer: updatedSimplifiedInvoiceHeaders,
    };
    try {
      await createSkuCollectedBills(currentCardData.id, payload);

      notificationHandler({
        messageText: `Invoice Header saved  Successfully.`,
        status: "SUCCESS",
      });
      // setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error sending payload:", error);
    } finally {
      setEditLoader(false); // Hide loader
    }
  };


 
  


  const handleDeleteRow = () => {
    triggerSimpleAjax(
      `${DELETE_COLLECTED_BILLS_SKU}${selectedSkuID}`,
      "DELETE",
      {},
      () => {
        const updatedTableData = [...tableData];
        updatedTableData.splice(selectedRowIndex, 1);
console.log("Add data in table 4");
        setTableData(updatedTableData);
        setRefreshData((prev) => !prev);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const simplifiedInvoiceSkuData =
    cardDataForSKus?.results[selectedCard]?.simplifiedinvoiceskudata || [];

  const [selectedSkuIds, setSelectedSkuIds] = useState([]);

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedSkuIds((prevSelectedIds) =>
      isChecked
        ? [...prevSelectedIds, id]
        : prevSelectedIds.filter((skuId) => skuId !== id)
    );
  };

  const handleExportToExcelButtonClick = (id) => {
    const payload = {
      data_to_export: [
        {
          id: selectedSkuIds,
        },
      ],
      workflow_object_id: props.outletDetails.workflow_object,
      add_on_sku: "Test",
    };

    createaddskuexcel(id, payload)
      .then((response) => {
        const filePath = response.file_path.replace(
          "/app/retailscan_backend/",
          `${AppConfig.api_url}`
        );
        window.open(filePath, "_blank");
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress size={80} style={{ color: "#318CE7" }} />{" "}
        </div>
      ) : (
        <>
          <div className="empty-card">
            <Slider
              {...sliderSettings}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                justifyContent: "start",
                gap: "8px",
              }}
            >
              {cardDataForSKus?.results.map((card, index) => (
                <Button
                  key={index}
                  onClick={() => handleCardClick(index)}
                  border={
                    savedCard !== null && index <= savedCard
                      ? "1px solid green"
                      : "1px solid #FFC72C"
                  }
                  bgColor="transparent"
                  color={
                    savedCard !== null && index <= savedCard
                      ? "green"
                      : "#FFC72C"
                  }
                  margin="8px 8px 0 0px" // Reduced margin
                  boxHeight="40px"
                  padding="0"
                  style={{ display: "inline-block", textAlign: "left" }} // Ensure the text is aligned properly
                >
                  <div className="button-content">
                    {savedCard !== null && index <= savedCard ? (
                      <CheckCircleIcon
                        style={{ color: "green", marginRight: "8px" }}
                      />
                    ) : selectedCard === index ? (
                      <RadioButtonCheckedIcon
                        style={{ color: "#FFC72C", marginRight: "8px" }}
                      />
                    ) : (
                      <RadioButtonUncheckedIcon
                        style={{ color: "#FFC72C", marginRight: "8px" }}
                      />
                    )}
                    <span
                      className="button-text"
                      style={{
                        color: "black",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                        maxWidth: "100%", // Ensure it takes up available space
                      }}
                      title={`${card.invoice_date}, ${card.invoice_no}`} // Display full text on hover
                    >
                      {`${card.invoice_date}, ${card.invoice_no}`}
                    </span>
                  </div>
                </Button>
              ))}

              <Button
                key="new-sku-data"
                onClick={() => handleCardClick("new-sku")}
                border="1px solid #FFC72C"
                bgColor="transparent"
                color="#FFC72C"
                margin="12px 12px 0 0px"
                boxHeight="40px"
              >
                <div className="button-content">
                  <span className="button-text">New SKU Data</span>
                </div>
              </Button>
            </Slider>
          </div>

          {selectedCard !== null && selectedCard !== "new-sku" && (
            <div className="table-container">
              <div
                className="empty-card"
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "barlow",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      textAlign: "left",
                      margin: 0, // Remove default margin for a cleaner look
                    }}
                  >
                    Invoice Details
                  </p>
                  {isExpanded ? (
                    <ExpandLessIcon
                      onClick={toggleExpandCollapse}
                      className="toggle-icon"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={toggleExpandCollapse}
                      className="toggle-icon"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>

                {isExpanded && (
                  <div
                    className="card-content"
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    {localInvoiceHeaders && localInvoiceHeaders.length > 0 ? (
                       <div
                       style={{
                         marginTop: "20px",
                         display: "grid",
                         gap:"8px",
                         gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                         justifyContent: "end",
                       }}
                     >
                        {localInvoiceHeaders.map((detail, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "12px",
                              backgroundColor: "#fafafa",
                              borderRadius: "6px",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              minWidth: "250px",
                              gridColumn:
                                detail.invoiceHeadersTypes === "pan_number"
                                  ? "span 2"
                                  : detail.invoiceHeadersTypes === "gst_number"
                                  ? "span 3"
                                  : "span 1",
                            }}

                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "barlow",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#333",
                                  whiteSpace: "nowrap",
                                  marginBottom: "6px",
                                }}
                              >
                                {detail.header}:
                              </span>

                              {detail.invoiceHeadersTypes ===
                                "multiple_image" && (
                                <input
                                  type="text"
                                  value={detail.singleInput}
                                  placeholder={`Enter ${detail.header}`}
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}
                              {detail.invoiceHeadersTypes === "dropdown" && (
                                <select
                                  value={
                                    detail.singleInput ||
                                    (detail.invoiceHeaderValidation.choices
                                      ? detail.invoiceHeaderValidation.choices
                                          .split(";")[0]
                                          .trim()
                                      : "")
                                  }
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                >
                                  {detail.invoiceHeaderValidation.choices &&
                                    detail.invoiceHeaderValidation.choices
                                      .split(";")
                                      .map((choice, idx) => (
                                        <option
                                          style={{
                                            lineHeight: "1.2",
                                          }}
                                          key={idx}
                                          value={choice.trim()}
                                        >
                                          {choice.trim()}
                                        </option>
                                      ))}
                                </select>
                              )}

                              {detail.invoiceHeadersTypes === "gst_number" && (
                                <>
                                  <input
                                    type="text"
                                    value={detail.singleInput}
                                    placeholder={`Enter ${detail.header}`}
                                    title={detail.singleInput}
                                    onChange={(e) =>
                                      handleSingleInputChange(e, index)
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "14px",
                                      color: "#666",
                                      border: errorIndexes.includes(index)
                                        ? "1px solid red"
                                        : "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "6px",
                                      flexGrow: 1,
                                      outline: "none",
                                      marginLeft: "10px",
                                      height: "40px",
                                      width: "calc(100% - 20px)",
                                      maxWidth: "80%",
                                    }}
                                  />
                                  <>
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      GST Legal Name:
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_legal_name
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_legal_name || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      GST Trade Name:
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_trade_name
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_trade_name || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      GST Registration Status:
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_registration_status
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_registration_status || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "8px",
                                        backgroundColor:
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_legal_name &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_registration_status &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_trade_name
                                            ? "rgb(76, 175, 80)"
                                            : "rgb(76, 175, 80)", // Adjust fallback color as needed
                                        color: "#fff",
                                        borderRadius: "2px",
                                        padding: "11px 10px",
                                        fontSize: "13px",
                                        cursor:
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_legal_name &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_registration_status &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_trade_name
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                      onClick={() => {
                                        if (!detail?.isVerified) {
                                          handleActionButtonClick(index);
                                        }
                                      }}
                                    >
                                      {detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_legal_name ||
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_registration_status ||
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_trade_name ? (
                                        <>
                                          <span style={{ marginRight: "8px" }}>
                                            Verified
                                          </span>
                                          <CheckCircleIcon
                                            style={{ fontSize: "16px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>Validate</span>
                                        </>
                                      )}
                                    </div>
                                  </>
                                </>
                              )}

                              {detail.invoiceHeadersTypes === "pan_number" && (
                                <>
                                  <input
                                    type="text"
                                    value={detail.singleInput}
                                    title={detail.singleInput}
                                    placeholder={`Enter ${detail.header}`}
                                    onChange={(e) =>
                                      handleSingleInputChange(e, index)
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "14px",
                                      color: "#666",
                                      border: errorIndexes.includes(index)
                                        ? "1px solid red"
                                        : "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "6px",
                                      flexGrow: 1,
                                      outline: "none",
                                      marginLeft: "10px",
                                      height: "40px",
                                      maxWidth: "80%",
                                    }}
                                  />
                                  <>
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      PAN Holder Name:-
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "8px",
                                        backgroundColor: detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name
                                          ? "rgb(76, 175, 80)" // Green for "Verified"
                                          : "rgb(76, 175, 80)", // Gray for "Validate"
                                        color: "#fff",
                                        borderRadius: "2px",
                                        padding: "11px 10px",
                                        fontSize: "13px",
                                        cursor: detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      onClick={() => {
                                        if (!detail.isVerified) {
                                          handlePanButtonClick(index);
                                        }
                                      }}
                                    >
                                      {detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.pan_holder_name ? (
                                        <>
                                          <span style={{ marginRight: "8px" }}>
                                            Verified
                                          </span>
                                          <CheckCircleIcon
                                            style={{ fontSize: "16px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>Validate</span>
                                        </>
                                      )}
                                    </div>
                                  </>
                                </>
                              )}

                              {detail.invoiceHeadersTypes === "text" && (
                                <input
                                  type="text"
                                  value={detail.singleInput}
                                  placeholder={`Enter ${detail.header}`}
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}
                               {detail.invoiceHeadersTypes === "number" && (
                                <input
                                  type="text"
                                  value={detail.singleInput}
                                  placeholder={`Enter ${detail.header}`}
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}

                              {detail.invoiceHeadersTypes === "date" && (
                                <input
                                  type="date"
                                  value={
                                    detail.singleInput
                                      ? detail.singleInput.replace(/\//g, "-")
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}

                              {detail.invoiceHeadersTypes === "time" && (
                                <input
                                  type="time"
                                  value={detail.singleInput}
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}
                            </div>

                            <div style={{ minHeight: "0px" }}>
                              <span
                                style={{
                                  color: "red",
                                  fontFamily: "barlow",
                                  fontSize: "12px",

                                  marginTop: "5px",
                                  wordWrap: "break-word",
                                  display: errorIndexes.includes(index)
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {errorMessages[index] ||
                                  `Please enter ${detail.header}`}
                              </span>
                            </div>
                          </div>
                        ))}
                        <div
                          style={{
                            gridColumn: "1 / -1", // Ensures it spans the full width
                            display: "flex",
                            justifyContent: "flex-end", // Aligns it to the right
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: "#4CAF50",
                              color: "white",
                              padding: "10px 20px",
                              fontSize: "14px", // Font size
                              fontWeight: "600", // Bold text
                              border: "none", // Remove border
                              borderRadius: "5px", // Rounded corners
                              cursor: "pointer", // Pointer on hover
                              transition: "background-color 0.3s", // Smooth hover effect
                            }}
                            onClick={() => handleSaveInvoiceHeader()} // Replace with your save logic
                            onMouseOver={(e) =>
                              (e.target.style.backgroundColor = "#45a049")
                            } // Hover effect
                            onMouseOut={(e) =>
                              (e.target.style.backgroundColor = green)
                            } // Reset hover effect
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <p style={{ textAlign: "center", color: "#999" }}>
                        No invoice details found.
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div>
                {tableData && tableData.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* New Button */}

                    <button
                      className="add-empty-row-button"
                      onClick={addEmptyRow}
                    >
                      <Add style={{ color: "#ffffff", marginRight: "8px" }} />
                      Add New Sku
                    </button>
                    <button
                      className="total-image"
                      onClick={() => {
                        // Assuming `props.workflow_object_id` contains the value you want to pass
                        const url = `${DASHBOARDROUTES.DASHBOARD_IMAGE_VIEWER.replace(
                          ":workflow_object_id",
                          props.outletDetails.workflow_object
                        )}`;
                        window.open(url, "_blank");
                      }}
                    >
                      <ImageIcon
                        style={{ color: "#ffffff", marginRight: "8px" }}
                      />
                      Total Images
                    </button>
                  </div>
                ) : null}
                <div>
                  {data.length === 0 ? (
                    <div className="no-data-container">
                      <span>No sku entries received in bill</span>
                    </div>
                  ) : (
                    <div className="empty-card" style={{ maxWidth: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1000,
                          padding: "10px 0",
                        }}
                      ></div>
                      <div
                        style={{
                          overflowX: "auto",
                          width: "100%",
                          marginTop: "-5%",
                        }}
                      >
                        <table
                          {...getTableProps()}
                          className="no-border"
                          style={{ width: "110%" }}
                        >
                          <thead>
                            {headerGroups.map(
                              (headerGroup, headerGroupIndex) => (
                                <tr
                                  {...headerGroup.getHeaderGroupProps()}
                                  key={`headerGroup-${headerGroupIndex}`}
                                >
                                  {headerGroup.headers.map(
                                    (column, columnIndex) => (
                                      <th
                                        {...column.getHeaderProps()}
                                        key={`column-${columnIndex}`}
                                        style={{
                                          padding: "10px",
                                          wordWrap: "break-word",
                                          textAlign: "center", // Added this line to center the text
                                          alignItems: "center",
                                        }}
                                      >
                                        {column.render("Header")}
                                      </th>
                                    )
                                  )}
                                  <th>Action</th>{" "}
                                  {/* Add Action header for the delete icon */}
                                </tr>
                              )
                            )}
                          </thead>
                          <tbody
                            {...getTableBodyProps()}
                            style={{ fontSize: "12px" }}
                          >
                            {console.log("Getting data:", rows)}
                            {rows.map((row, rowIndex) => {
                              prepareRow(row);
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  key={`row-${rowIndex}`}
                                  style={{ height: "30px" }}
                                >
                                  {/* SKU Name Column First */}
                                  {row.cells
                                    .filter(
                                      (cell) =>
                                        cell.column.id === "sku_name" ||
                                        cell.column.id === "add_on_sku"
                                    )
                                    .map((cell, cellIndex) => (
                                      <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        style={{
                                          padding: "4px 8px",
                                          verticalAlign: "middle",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                        >
                                          {cell.column.id === "sku_name" ? (
                                            <div>
                                              <CustomDropdown
                                                options={results.map(
                                                  (item) => item.sku_name
                                                )} // Only sku_name is shown in dropdown
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || ""
                                                }
                                                onChange={(selectedValue) => {
                                                  // Find the corresponding sku for the selected sku_name
                                                  const selectedSku =
                                                    results.find(
                                                      (item) =>
                                                        item.sku_name ===
                                                        selectedValue
                                                    )?.sku;

                                                  const updatedTableData = [
                                                    ...tableData,
                                                  ];
                                                  updatedTableData[rowIndex][
                                                    cell.column.id
                                                  ] = selectedValue;
                                                  updatedTableData[
                                                    rowIndex
                                                  ].sku = selectedSku || ""; // If no sku found, set empty

                                                  updatedTableData[
                                                    rowIndex
                                                  ].add_on_sku = "";
                                                  console.log(
                                                    "Add data in table 5"
                                                  );
                                                  setTableData(
                                                    updatedTableData
                                                  );
                                                }}
                                              />

                                              {tableData[rowIndex]?.[
                                                cell.column.id
                                              ] &&
                                                !results.some(
                                                  (item) =>
                                                    item.sku_name ===
                                                    tableData[rowIndex][
                                                      cell.column.id
                                                    ].value
                                                ) && (
                                                  <div
                                                    style={{
                                                      color: "#FFC72C",
                                                      marginTop: "3px",
                                                      fontSize: "11px",
                                                    }}
                                                  >
                                                    {
                                                      tableData[rowIndex][
                                                        cell.column.id
                                                      ].value
                                                    }
                                                  </div>
                                                )}

                                              {/* Display the add_on_sku value below the dropdown if it exists */}
                                              {tableData[rowIndex]
                                                ?.add_on_sku && (
                                                <div
                                                  style={{
                                                    fontSize: "11px",
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    marginTop: "5px", // Optional: Add some spacing between the dropdown and add_on_sku
                                                  }}
                                                >
                                                  Add-on SKU:{" "}
                                                  {
                                                    tableData[rowIndex]
                                                      .add_on_sku
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {/* In case this cell is for add_on_sku, display it here */}
                                              <div
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {tableData[rowIndex]
                                                  ?.add_on_sku ||
                                                  "No Add-on SKU"}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    ))}

                                  {/* Other Columns */}

                                  {row.cells
                                    .filter(
                                      (cell) => cell.column.id !== "sku_name"
                                    )
                                    .map((cell, cellIndex) => (
                                      <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        style={{
                                          padding: "4px 8px",
                                          verticalAlign: "middle",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                        >
                                          <>
                                            <span
                                              style={{
                                                visibility: "hidden",
                                                position: "absolute",
                                                padding: "0",
                                                fontFamily: "inherit",
                                                fontSize: "inherit",
                                              }}
                                              id={`span-${rowIndex}-${cell.column.id}`}
                                            >
                                              {"6"}
                                            </span>

                                            {cell.value?.type ===
                                              "dropdown" && (
                                              <select
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]?.value || "6"
                                                }
                                                onChange={(event) => {
                                                  const value =
                                                    event.target.value;
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    value,
                                                    "dropdown"
                                                  );
                                                }}
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px",

                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "70px",
                                                }}
                                              >
                                                {cell.value?.validations
                                                  ?.choices &&
                                                  cell.value.validations.choices
                                                    .split(";")
                                                    .map((choice, index) => (
                                                      <option
                                                        key={index}
                                                        value={choice.trim()}
                                                      >
                                                        {choice.trim()}
                                                      </option>
                                                    ))}
                                              </select>
                                            )}

                                            {cell.value?.type === "number" && (
                                              <input
                                                type="text"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]?.value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "number"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? ""
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type === "date" && (
                                              <input
                                                type="date"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ].value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "date"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? ""
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type === "time" && (
                                              <input
                                                type="time"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ].value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "time"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? ""
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type === "text" && (
                                              <input
                                                type="text"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ].value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "text"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? ""
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            <>
                                              {validationErrors.length === 0 &&
                                                !cell.value && (
                                                  <span
                                                    style={{
                                                      color: "gray",
                                                      fontSize: "12px",
                                                      marginTop: "4px",
                                                      display: "block",
                                                      fontFamily: "barlow",
                                                    }}
                                                  >
                                                    Enter text
                                                  </span>
                                                )}

                                              <hr
                                                style={{
                                                  margin: "0px 0",
                                                  borderColor:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "red"
                                                      : "gray",
                                                  borderWidth: "1px",
                                                }}
                                              />

                                              {/* Show Validation Errors below the HR tag */}
                                              {validationErrors
                                                .filter(
                                                  (error) =>
                                                    error.rowIndex ===
                                                      rowIndex &&
                                                    error.columnId ===
                                                      cell.column.id
                                                )
                                                .map((error, index) => (
                                                  <span
                                                    key={index}
                                                    style={{
                                                      color: "red",
                                                      fontSize: "10px",
                                                      marginTop: "4px",
                                                      display: "block",
                                                      fontFamily: "barlow",
                                                    }}
                                                  >
                                                    {error.message}
                                                  </span>
                                                ))}
                                            </>
                                          </>
                                        </div>
                                      </td>
                                    ))}

                                  <td style={{ textAlign: "center" }}>
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        handleOpenDialog(
                                          simplifiedInvoiceSkuData[rowIndex].id,
                                          rowIndex
                                        )
                                      } // Pass skuID and rowIndex here
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {/* Dialog */}
                      <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Deletion"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this row?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseDialog}
                            color="primary"
                            bgColor="lightgrey"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={confirmDelete}
                            color="primary"
                            autoFocus
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="save-next-button"
                onClick={handleSaveNext}
                disabled={hasNewSku || loading} // Disable button when loading
              >
                {editLoader ? "Please Wait.." : "Save and Next"}{" "}
                {/* Show loader text */}
              </button>
            </div>
          )}

          {selectedCard === "new-sku" && (
            <div>
              <p
                style={{
                  fontFamily: "barlow",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                {/* Add any text here if needed */}
              </p>

              {/* Container to align the button to the right */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Button
                  size="medium"
                  bgColor={black300}
                  onClick={handleExportToExcelButtonClick}
                  style={{ marginRight: "10px" }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#3E96C8",
                    }}
                  >
                    <ExcelIcon />
                    Export to CSV
                  </span>
                </Button>
              </div>

              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>New SKU's</th>
                    <th>Manufacturer</th>
                    <th>Brand</th>
                    <th>Variant</th>
                    <th>Sub Category</th>
                  </tr>
                </thead>
                <tbody>
                  {newAddedSKuTest.map((row, rowIndex) =>
                    row.simplifiedinvoiceaddonsku.map((sku, skuIndex) => (
                      <tr key={`${rowIndex}-${skuIndex}`}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckboxChange(sku.id, e.target.checked)
                            }
                          />
                        </td>
                        <td>{sku.add_on_sku || "N/A"}</td>
                        <td>{sku.manufacturer || "N/A"}</td>
                        <td>{sku.brand || "N/A"}</td>
                        <td>{sku.variant || "N/A"}</td>
                        <td>{sku.sub_category || "N/A"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    notificationHandler: bindActionCreators(triggerNotification, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CollectedBills);